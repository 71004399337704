<template>
  <div class="h-full">
    <fixed-header v-if="$isMobile()">
      <div class="mobile-fixed-header sidebar">
        <b-collapse
          animation="slide"
          :open.sync="isCollapseOpen"
          class="w-full bg-white"
          style="z-index: 999"
        >
          <template slot="trigger">
            <div class="flex items-center justify-between p-4 mobile-header">
              <p class="font-bold">{{ mobileTitle }}</p>
              <b-icon icon="bars" size="is-medium"></b-icon>
            </div>
          </template>
          <b-menu class="mobile-sidebar" :accordion="false" :activable="false">
            <b-menu-list>
              <b-menu-item
                @click.native="isCollapseOpen = false"
                tag="router-link"
                to="/"
                label="Main"
              ></b-menu-item>
              <b-menu-item
                @click.native="isCollapseOpen = false"
                tag="router-link"
                to="/about"
                label="About mono"
              ></b-menu-item>
              <b-menu-item expanded>
                <template #label="props">
                  Projects
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'angle-down' : 'angle-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/all"
                  label="All"
                ></b-menu-item>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/wholesale"
                  label="Wholesale"
                ></b-menu-item>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/clothing"
                  label="Clothing shop"
                ></b-menu-item>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/commercial"
                  label="Commercial"
                ></b-menu-item>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/studio"
                  label="Studio"
                ></b-menu-item>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/office"
                  label="Office"
                ></b-menu-item>
                <b-menu-item
                  @click.native="isCollapseOpen = false"
                  tag="router-link"
                  to="/projects/living"
                  label="Living"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                @click.native="isCollapseOpen = false"
                tag="router-link"
                to="/process"
                label="Process"
              ></b-menu-item>
              <b-menu-item
                @click.native="isCollapseOpen = false"
                tag="router-link"
                to="/counseling"
                label="Counseling"
              ></b-menu-item>
              <b-menu-item
                @click.native="isCollapseOpen = false"
                tag="router-link"
                to="/license"
                label="License"
              ></b-menu-item>
              <b-menu-item
                @click.native="isCollapseOpen = false"
                tag="router-link"
                to="/press"
                label="Press"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
          <div class="mobile-sidebar-footer">
            <b-icon
              class="cursor-pointer"
              @click.native="onClickInstagram"
              pack="fab"
              icon="instagram"
              size="is-large"
              style="width: 1.5rem !important"
            ></b-icon>
            <b-icon
              class="cursor-pointer"
              @click.native="onClickYoutube"
              pack="fab"
              icon="youtube"
              size="is-large"
              style="width: 1.5rem !important; margin-left: 8px"
            ></b-icon>
            <p>T +82 2 2248 5048</p>
            <p>F +82 2 2247 5048</p>
            <p class="mb-4">space@themono.co.kr</p>
            <p>1st Floor, 113-7 Sindang-dong</p>
            <p>Jung-gu, Seoul, KOREA</p>
          </div>
        </b-collapse>
      </div>
    </fixed-header>
    <div v-if="$isMobile()" class="h-full m-0 columns">
      <div class="p-0 column content">
        <keep-alive include="Press,Project,Main">
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </div>
    </div>
    <div v-else class="h-full m-0 columns">
      <div class="flex flex-col p-0 column is-4">
        <b-sidebar
          class="flex-1 tracking-widest bg-white w-100 sidebar"
          position="fixed"
          mobile="hide"
          :expand-on-hover="false"
          :reduce="false"
          :delay="500"
          :can-cancel="false"
          type="is-light"
          open
          fullheight
        >
          <div>
            <div
              @click="onClickLogo"
              class="cursor-pointer sidebar-title"
              style="font-size: 16px; margin-bottom: 5rem"
            >
              <p class="mb-1">CREATE</p>
              <p class="mb-1">YOUR&nbsp;&nbsp;SPACE</p>
              <p class="mb-1">+</p>
              <p class="mb-1 underline">MONO</p>
            </div>
            <b-menu :accordion="false" :activable="false">
              <b-menu-list>
                <b-menu-item
                  :active="currentRoute === 'about'"
                  tag="router-link"
                  to="/about"
                  label="About mono"
                ></b-menu-item>
                <b-menu-item :active="currentRoute === 'projects'" expanded>
                  <template #label="props">
                    Projects
                    <b-icon
                      class="is-pulled-right"
                      :icon="props.expanded ? 'angle-down' : 'angle-up'"
                    ></b-icon>
                  </template>
                  <b-menu-item
                    :active="currentRouteCategory === 'all'"
                    tag="router-link"
                    to="/projects/all"
                    label="All"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                  <b-menu-item
                    :active="currentRouteCategory === 'wholesale'"
                    tag="router-link"
                    to="/projects/wholesale"
                    label="Wholesale"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                  <b-menu-item
                    :active="currentRouteCategory === 'clothing'"
                    tag="router-link"
                    to="/projects/clothing"
                    label="Clothing shop"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                  <b-menu-item
                    :active="currentRouteCategory === 'commercial'"
                    tag="router-link"
                    to="/projects/commercial"
                    label="Commercial"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                  <b-menu-item
                    :active="currentRouteCategory === 'studio'"
                    tag="router-link"
                    to="/projects/studio"
                    label="Studio"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                  <b-menu-item
                    :active="currentRouteCategory === 'office'"
                    tag="router-link"
                    to="/projects/office"
                    label="Office"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                  <b-menu-item
                    :active="currentRouteCategory === 'living'"
                    tag="router-link"
                    to="/projects/living"
                    label="Living"
                    style="font-size: 0.8rem"
                  ></b-menu-item>
                </b-menu-item>
                <b-menu-item
                  :active="currentRoute === 'process'"
                  tag="router-link"
                  to="/process"
                  label="Process"
                ></b-menu-item>
                <b-menu-item
                  :active="currentRoute === 'counseling'"
                  tag="router-link"
                  to="/counseling"
                  label="Counseling"
                ></b-menu-item>
                <b-menu-item
                  :active="currentRoute === 'license'"
                  tag="router-link"
                  to="/license"
                  label="License"
                ></b-menu-item>
                <b-menu-item
                  :active="currentRoute === 'press'"
                  tag="router-link"
                  to="/press"
                  label="Press"
                ></b-menu-item>
              </b-menu-list>
              <b-menu-list v-if="isLogin" label="관리자 메뉴">
                <b-menu-item tag="router-link" to="/admin" label="게시물 작성"></b-menu-item>
                <b-menu-item tag="router-link" to="/v" label="상담 게시물 조회"></b-menu-item>
                <b-menu-item @click="onClickLogout" label="로그아웃"></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
          <div class="sidebar-footer">
            <b-icon
              class="cursor-pointer"
              @click.native="onClickInstagram"
              pack="fab"
              icon="instagram"
              size="is-large"
              style="width: 1.5rem !important"
            ></b-icon>
            <b-icon
              class="cursor-pointer"
              @click.native="onClickYoutube"
              pack="fab"
              icon="youtube"
              size="is-large"
              style="width: 1.5rem !important; margin-left: 8px"
            ></b-icon>
            <p>T +82 2 2248 5048</p>
            <p>F +82 2 2247 5048</p>
            <p class="mb-4">space@themono.co.kr</p>
            <p>1st Floor, 113-7 Sindang-dong</p>
            <p>Jung-gu, Seoul, KOREA</p>
          </div>
        </b-sidebar>
      </div>
      <div class="p-0 column content">
        <keep-alive include="Press,Project,Main">
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import store from '../src/store/index';
import { useRouter } from '../src/use-router';
import FixedHeader from 'vue-fixed-header';

export default defineComponent({
  components: {
    FixedHeader,
  },
  setup(_, context) {
    const isLogin = computed(() => store.getters.isLogin);
    const router = useRouter();
    const isCollapseOpen = ref(false);
    const mobileTitle = ref('DESIGN STUDIO MONO');

    function onClickWrite() {
      const path = router.currentRoute.path;
      if (path == '/') {
        router.push({ name: 'Admin', query: { category: 'projects' } });
        return;
      }

      router.push({
        name: 'Admin',
        query: { category: router.currentRoute.path.replace('/', '') },
      });
    }

    const currentRoute = computed(() => context.root.$route.path.replace('/', '').split('/')[0]);
    const currentRouteCategory = computed(() => {
      if (context.root.$route.path.replace('/', '').split('/')[0] === 'projects') {
        return context.root.$route.path.replace('/', '').split('/')[1];
      }

      return '';
    });

    function onClickLogout() {
      store.dispatch('logout');
    }

    function onClickLogo() {
      router.push('/').catch(() => {});
    }

    function onClickInstagram() {
      window.open('https://www.instagram.com/monodesign_official/');
    }

    function onClickYoutube() {
      window.open('https://www.youtube.com/@designstudiomono/');
    }

    watch(currentRoute, (v) => {
      if (v === 'detail') {
        mobileTitle.value = context.root.$route.params.title;
      } else {
        mobileTitle.value = 'DESIGN STUDIO MONO';
      }
    });

    return {
      router,
      isLogin,
      isCollapseOpen,

      onClickWrite,
      onClickLogout,
      onClickLogo,
      onClickInstagram,
      onClickYoutube,

      currentRoute,
      currentRouteCategory,

      mobileTitle,
    };
  },
});
</script>

<style lang="scss">
@font-face {
  font-family: 'GmarketSansLight';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpoqaHanSansNeo-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Regular.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
}

.sidebar {
  font-family: 'GmarketSansLight';
}

.content {
  font-family: 'SpoqaHanSansNeo-Regular';
}

.sidebar-title {
  background-color: white;
  font-weight: bold;
}

.sidebar-content {
  box-shadow: none !important;
  padding: 15px !important;
  font-weight: 500;
  font-size: 14px;
  justify-content: space-between;
  width: 33.33333337% !important;
  background-color: white !important;
}

.sidebar-content .menu {
  font-size: 0.9rem !important;
}

.sidebar-content .menu-list a {
  transition: all 0.4s ease;
  padding: 0.8em 0.75em;
}

.sidebar-content .menu-list a:hover {
  background-color: white;
  color: #999;
}

.sidebar-content .menu-list a.is-active {
  background-color: white;
  font-weight: bold;
  color: black;
}

.sidebar-content .menu-list li ul {
  border-left: none !important;
  padding: 0.5em 0.75em !important;
  margin: 0;
}

.mobile-sidebar .menu-list li ul {
  border-left: none !important;
  font-size: 0.9rem;
}

.sidebar-footer {
  background-color: white;
  font-size: 12px;
}

.is-pulled-right.icon {
  height: 1.4rem !important;
}

.mobile-fixed-header {
  display: flex;
  z-index: 999;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.mobile-fixed-header.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.mobile-header {
  background-color: whitesmoke;
}

.mobile-header.vue-fixed-header--isFixed {
  position: fixed;
  left: 0%;
  top: 0;
  width: 100%;
  z-index: 999;
}

.mobile-sidebar-footer {
  padding: 0.5em 0.75em;
  font-size: 0.8rem;
}
</style>
